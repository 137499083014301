<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h3 class="display-1 font-weight-bold mb-3">PDI Manager</h3>
        <v-btn v-on:click="syncNow" v-if="!isSyncing" block>
          <span>Get Manufacturer</span>
        </v-btn>
        <v-card v-if="isSyncing">
          Trying to get Data... this will take a few minutes
          <br />
          <br />
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <br />
        </v-card>

        <p class="subheading font-weight-regular">
          <br />
        </p>
        <h3 class="display-1 font-weight-bold mb-3">
          {{ selectedManufacturer }}
        </h3>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="apiMessage.result"
          :items-per-page="20"
          @click:row="handleClick"
          item-key="manufacturerId"
          :class="{ selectedRow: apiMessage.result === selectedItem }"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dal from '../services/dal.js';
export default {
  name: 'MftList',

  data: () => ({
    formTitle: null,
    dialog: false,
    dialogDelete: false,
    selectedManufacturerId: 0,
    selectedManufacturer: '-',
    headers: [
      {
        text: 'Manufacturer Name',
        align: 'start',
        sortable: true,
        value: 'manufacturerName',
      },
      { text: 'manufacturerId', value: 'manufacturerId' },
      { text: 'updateDate', value: 'updateDate' },
      { text: 'createDate', value: 'createDate' },
      { text: 'isActive', value: 'isActive' },
    ],
    singleSelect: true,
    selected: [],
    selectedItem: null,
    isSyncing: false,
    apiMessage: {},
  }),
  computed: {
    accessToken() {
      return this.$store.state.token;
    },
  },
  mounted() {
    this.syncNow();
  },

  methods: {
    syncNow() {
      this.isSyncing = true;
      dal.getManufacturers(this.accessToken).then((data) => {
        this.isSyncing = false;
        this.apiMessage = data.data;
        this.apiMessage.result.map((item, index) => {
          item.selectedItem = false;
          this.$set(this.apiMessage.result, index, item);
        });
      });
    },

    handleClick(row) {
      // set active row and deselect others
      this.selectedManufacturer = row.manufacturerName;
      this.selectedItem = row;
      this.$store.commit('setManufacturerId', row.manufacturerId);
      this.emitSearchEvent(row.manufacturerId);
    },

    emitSearchEvent(manufacturerId) {
      this.$emit('selectedMft', manufacturerId);
    },

    highlightClickedRow(event) {
      let tr = event.target.parentNode;
      tr.classList.add('highlight');
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: red;
}
.selectedRow {
  background-color: red;
  font-weight: bold;
}
</style>
