<template>
  <div class="about">
    <MftList @selectedMft="selectedMftMethod" />
    <MftMappingsDestinationToSource ref="MftMappingsDestinationToSource" />
  </div>
</template>
<script>
import MftList from "../components/MftList";
import MftMappingsDestinationToSource from "../components/MftMappingsDestinationToSource";

export default {
  name: 'About',

  components: {
    MftList,
    MftMappingsDestinationToSource,
  },
  methods: {
    selectedMftMethod: function(manufacturerId) {
      this.$refs.MftMappingsDestinationToSource.getDestinationToSourceMappings(manufacturerId);
    },
  },
};
</script>
