<template>
  <div>
    <v-row class="pl-3 pt-3">
      <v-col cols="8">
        <h3>PDI Tool Help</h3>
        <div class="mb-4"></div>
        <p>
          This PDI tool allows for setting up manufacturers for backend data imports. The tool
          reads a JSON file from a file storage location in order to analyze the file. Then the file can be 
          mapped through an assisted process. When the field mapping process is complete, the Manufacturer Mapping needs 
          to be published to become live. 
        </p>
        <p>
          Once mapped, the import process can be triggered from this tool. The backend process runs as an 
          asynchronous process. Each process run is visible through the Intake View screen.
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'Help',
  created() {},
  methods: {},
};
</script>
